import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./dragAndDropUploaderForOne.scss";
import docImg from "../../../../assets/svg/document-upload.svg";
import { useState } from "react";
import LoadingContent from "../LoadingModal/LoadingContent";
export const DragAndDropUploaderForOne = ({ pdfFilesUploaded, onDropped }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileToBig, setFileToBig] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      pdfFilesUploaded(acceptedFiles);
    },
    [pdfFilesUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (e) => {
      onDrop(e);
      setIsUploading(true);
    },
    onDropRejected: () => {
      setIsUploading(false);
      setFileToBig(true);
    },
    onDropAccepted: () => {
      setIsUploading(false);
    },
    accept: ".pdf",
    multiple: false,
    //MAX 3MB
    maxSize: 3000000,
  });

  if (isUploading) return <LoadingContent />;

  return (
    <>
      <div className="drop-zone" {...getRootProps()}>
        <img src={docImg} alt={docImg} />
        <input type="file" {...getInputProps()} />
        <p>Przeciągnij tutaj dokument PDF lub kliknij.</p>
      </div>
      {fileToBig && (
        <p style={{ color: "#a83244" }}>
          Dokument jest zbyt duży. Maksymalny rozmiar to 3MB.
        </p>
      )}
    </>
  );
};
