import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import "./cardCreatorPage.scss";

const DocumentSentModal = ({
  success,
  setShowDocumentSentModal,
  message = "",
}) => {
  const history = useHistory();
  return (
    <div className="document-sent">
      <div className="document-sent__content">
        <div>
          {success ? (
            <TiTick
              style={{
                color: "#02a10c",
                width: "200px",
                height: "200px",
                margin: "0 auto",
              }}
            />
          ) : (
            <ImCross
              style={{
                color: "#eb1b00",
                width: "200px",
                height: "200px",
                margin: "0 auto",
              }}
            />
          )}

          <p style={{ color: success ? "#02a10c" : "#eb1b00" }}>
            {success
              ? "Dokument został wysłany"
              : "Nie udało się wysłać dokumentu. " + message}
          </p>
        </div>
        <button
          onClick={() => {
            setShowDocumentSentModal(false);
            if (success) window.location.href = "/";
          }}
        >
          {success ? "Zakończ" : "Powrót"}
        </button>
      </div>
    </div>
  );
};

export default DocumentSentModal;
