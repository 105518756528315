export const ROUTES = {
  home: '/',
  document: '/document/:id',
  mobileTest: '/mobile',
};

export const ROUTES_ICSIGN = {
  home: '/',
  cardSignatureExample: '/card/:id',
  signatureFragment:'/signature',
  verifySignature:'/verify',
  userIdentify:'/identify',
  biometricSignature:'/biometric',
  documentSign: '/document/:id',
  group:'/group/document/:id'
};