import { createAsyncThunk } from "@reduxjs/toolkit";
import { documentsService } from "../../app/services/documentsService";

export const getDocuments = createAsyncThunk(
  "[DOCUMENT] GET_DOCS",
  async (userGuid) => {
    const documents = await documentsService.getDocuments(userGuid);
    return documents;
  }
);

export const getDocumentById = createAsyncThunk(
  "[DOCUMENT] GET_DOC_BY_ID",
  async (id) => {
    const user = await documentsService.getDocumentById(id);
    return user;
  }
);
export const getPartOfSignature = createAsyncThunk(
  "[DOCUMENT] GET_PART_OF_SIGNATURE",
  async (userGuid) => {
    const signImage = await documentsService.getPartOfSignature(userGuid);
    return signImage;
  }
);
export const createNewDocumentWithSignatures = createAsyncThunk(
  "[DOCUMENT] CREATE_NEW_DOC",
  async (userGuid) => {
    const signImage = await documentsService.createNewDocumentWithSignatures(
      userGuid
    );
    return signImage;
  }
);
export const updateNewDocumentWithSignatures = createAsyncThunk(
  "[DOCUMENT] UPDATE_NEW_DOC",
  async (data) => {
    const signImage = await documentsService.updateNewDocumentWithSignatures(
      data
    );
    return signImage;
  }
);
export const signDocumentWithBiometricData = createAsyncThunk(
  "[DOCUMENT] SIGN_DOC_WITH_BIOMETRIC_DATA",
  async (data) => {
    const signedDocument = await documentsService.signDocumentWithBiometricData(
      data
    );
    return signedDocument;
  }
);

export const biometricVerification = createAsyncThunk(
  "[DOCUMENT] VERIFICATION",
  async (data) => {
    const document = await documentsService.biometricVerification(
      data.data,
      data.userGuid
    );
    return document;
  }
);

export const biometricIdentification = createAsyncThunk(
  "[DOCUMENT] IDENTIFICATION",
  async (data) => {
    const document = await documentsService.biometricIdentification(data);
    return document;
  }
);

export const createDocumentWithFile = createAsyncThunk(
  "[DOCUMENT] CREATE",
  async (data) => {
    const document = await documentsService.createDocumentWithFile(data);
    return document;
  }
);

export const getDocumentEncryptionKey = createAsyncThunk(
  "[DOCUMENT] GET_ENCRYPTION_KEY",
  async (data) => {
    const key = await documentsService.getDocumentEncryptionKey();
    return key;
  }
);

export const getDocumentsSigningFields = createAsyncThunk(
  "[DOCUMENT] GET_SIGNING_FIELDS",
  async (data) => {
    const response = await documentsService.getDocumentSigningFields(data);
    return response;
  }
);

export const checkIfSignaturesChartExists = createAsyncThunk(
  "[DOCUMENT] CHECK_IF_SIGNATURES_CHART_EXISTS",
  async () => {
    const response = await documentsService.checkIfSignaturesChartExists();
    return response;
  }
);
