import "./loadingModal.scss";
import LoadingContent from "./LoadingContent";

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-modal-background">
        <LoadingContent />
      </div>
    </div>
  );
};

export default LoadingModal;
