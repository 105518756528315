export default class Pdf {
  constructor(container) {
    this.container = container;
    this.counter = 0;
  }

  async createCanvasBasedOnImg(imageUrl, onLoadCanvas) {
    this.counter++;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.setAttribute("page", `${this.counter}`);
    canvas.id = `canvas-${this.counter}`;

    const image = await this.createImage(imageUrl);
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.classList.add("pdf-canvas", "shadow-lg");

    ctx?.drawImage(image, 0, 0, image.width, image.height);
    this.container.current?.appendChild(canvas);
    console.log("ON LOAD CANVAS", onLoadCanvas);
    if (onLoadCanvas) onLoadCanvas();
  }

  async createImage(src) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = src;
    });
  }

  remove() {
    this.container.current?.replaceChildren();
  }
}
