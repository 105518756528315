import { useRef, useEffect, useMemo, useState } from "react";
import Pdf from "app/services/pdfService";
import { useDocument } from "app/hooks/useDocument";
import BiometricDataHandler from "app/services/biometricDataHandler";

export const PdfViewer = ({
  setData = null,
  base64Document,
  signatureFields = [],
  skeletonLoadingComponent,
  onChange,
}) => {
  const { pages } = useDocument(base64Document);
  const canvasWrapperRef = useRef(null);
  const canvas = new Pdf(canvasWrapperRef);
  const [isCanvasLoading, setIsCanvasLoading] = useState(true);

  const bdh = useMemo(
    () =>
      new BiometricDataHandler("guid", "documentID", pages, signatureFields),
    [signatureFields, pages]
  );
  useEffect(() => {
    if (setData) {
      bdh.setOnChange(onChange);
      setData(bdh);
    }
  }, [bdh, setData]);

  // add biometric handlers
  useEffect(() => {
    setTimeout(() => {
      if (pages.length) {
        bdh.prepareDataHandler(pages.length);
        bdh.addEventHandlers(pages);
      }
    }, 1000);
  }, [pages, bdh]);

  useEffect(() => {
    pages.forEach((canvasURL) =>
      canvas.createCanvasBasedOnImg(canvasURL, () => setIsCanvasLoading(false))
    );

    return () => {
      canvas.remove();
    };
  }, [pages]);

  return (
    <div style={{ width: "100%" }}>
      <div className="pdf-canvas-wrapper" ref={canvasWrapperRef} />
      {isCanvasLoading && skeletonLoadingComponent}
    </div>
  );
};
