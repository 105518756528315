import { IButton } from "app/components/common/Button.jsx";
import { Footer } from "app/components/common/Footer/Footer.jsx";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { documentsState } from "../../../../store/documents";
import { userData } from "../../../../store/account";
import DocumentSentModal from "../cardCreatorPage/DocumentSentModal";
import LoadingModal from "app/components/common/LoadingModal/LoadingModal";
import {
  addScanDocumentForUpload,
  removeDocumentForUpload,
  removeSignatureFromDoc,
} from "../../../../store/documents/documents.reducer";
import {
  signDocumentWithBiometricData,
  createDocumentWithFile,
  getDocumentsSigningFields,
  getDocumentById,
} from "../../../../store/documents/documents.thunks";
import { PdfViewer } from "../../common/PdfViewer";
import { DragAndDropUploaderForOne } from "../../common/drag-and-drop-uploader-for-one/DragAndDropUploaderForOne";
import "./biometricSignaturePage.scss";
import CheckboxAgreement from "app/components/common/CheckboxAgreement/CheckboxAgreement";
import { SigningAnimation } from "app/components/common/SigningAnimation/SigningAnimation";
import BiometricDataHandler from "../../../services/biometricDataHandler";
import LoadingContent from "app/components/common/LoadingModal/LoadingContent";
import useAuth from "app/components/common/useAuth";

export const BiometricSignaturePage = () => {
  useAuth();
  const [data, setData] = useState("");
  const [signatureFields, setSignatureFields] = useState([]);
  const [showDocumentSentModal, setShowDocumentSentModal] = useState(false);
  const [documentSentSuccess, setDocumentSentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();
  const [createdDocumentId, setCreatedDocumentId] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const ReduxStoreData = useSelector(documentsState);
  const user = useSelector(userData);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationPlayCount, setAnimationPlayCount] = useState(0);

  const bdh = new BiometricDataHandler();

  const onPdfFilesUploaded = async (files) => {
    if (files.length === 0) {
      // need to add "ALERT "   Mozesz dodać wyłącznie PDF !
      // dispatch({ type: UploadDocumentActions.SET_ALERT, payload: null })
      return;
    } else {
      const reader = new FileReader();
      reader.onload = async (e) => {
        await dispatch(addScanDocumentForUpload(e.target.result));
        createDocument(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const createDocument = async (result) => {
    setIsLoading(true);
    const resposne = await dispatch(
      createDocumentWithFile({
        documentName: "name",
        documentContent: result.split("base64,")[1],
      })
    );
    if (resposne) {
      setCreatedDocumentId(resposne.payload);
      setIsLoading(false);
    }
  };

  // const update = (data) => {
  //   dispatch(updateSignatureToDocument(data));
  // };

  const handelSignature = () => {
    // const viewer = document.getElementById('container');
    // console.log('viewer: ', viewer);
    // viewer.ej2_instances[0].annotationModule.setAnnotationMode(
    //   'HandWrittenSignature'
    // );
  };

  const onHandelBack = () => {
    history.push("/");
    dispatch(removeDocumentForUpload());
    dispatch(removeSignatureFromDoc());
  };

  const endAnimationFunction = async () => {
    setAnimationPlayCount(animationPlayCount + 1);
    if (animationPlayCount === 2) {
      setDocumentSentSuccess(false);
      setShowDocumentSentModal(true);
      setPlayAnimation(false);
      return;
    }
    if (!documentSentSuccess) {
      setPlayAnimation(true);
      return;
    }
    if (documentSentSuccess) {
      const documentResponse = await dispatch(
        getDocumentById(createdDocumentId)
      );
      if (documentResponse) {
        downloadPdf(
          documentResponse.payload.documentContent,
          documentResponse.payload.documentName
        );
      }
    }
  };

  const checkSigns = async () => {
    const signatureFieldsRes = await dispatch(
      getDocumentsSigningFields(
        ReduxStoreData.documentForBiometricSign.split(
          "data:application/pdf;base64,"
        )[1]
      )
    );
    if (signatureFieldsRes) {
      const signatures = [];
      signatureFieldsRes.payload.extractedSignatures.forEach((e, index) => {
        const { left, width, height, fieldName, pageNumber } = e;
        signatures.push({
          bottom: signatureFieldsRes.payload.height - e.bottom,
          left,
          width,
          height,
          fieldName,
          pageNumber,
        });
      });
      setSignatureFields(signatures);
    }
  };

  const downloadPdf = (pdfContent, pdfName) => {
    const source = `data:application/pdf;base64,${pdfContent}`;
    const link = document.createElement("a");
    const fileName = pdfName;
    link.href = source;
    link.download = fileName;
    link.click();
  };

  const onHandelSendDocument = async () => {
    await checkSigns();
    setPlayAnimation(true);
    const bdhObject = data.obj;
    const biometricData = data.getBiometricData();
    const filteredRegions = biometricData.regions.filter((e, index) => {
      if (e.strokes.length > 0) {
        const filteredStrokes = e.strokes.map((stroke) => {
          return stroke.points;
        });
        e.strokes = filteredStrokes;
        return true;
      }
      return false;
    });

    const filteredPages = bdhObject.pages.filter((e) => {
      if (e.strokes.length === 0) return false;
      return true;
    });
    biometricData.regions = filteredRegions;
    bdhObject.pages = filteredPages;
    const objectToSend = {
      documentId: createdDocumentId,
      presentationId: "",
      userId: user.sub,
      startFilling: bdhObject.startFilling,
      endFilling: bdhObject.endFilling,
      zeroIsTop: bdhObject.zeroIsTop,
      dpi: 72,
      pages: bdhObject.pages,
      inputs: bdhObject.inputs,
      biometricData: {
        ...biometricData,
      },
    };
    setIsLoading(true);
    const res = await dispatch(signDocumentWithBiometricData(objectToSend));
    if (res) {
      setIsLoading(false);
      if (res.payload === "") setDocumentSentSuccess(true);
      else setDocumentSentSuccess(false);
      setShowDocumentSentModal(true);
    }
  };

  return (
    <div className="wrapper-block">
      {/* {isLoading && <LoadingModal />} */}

      {ReduxStoreData.documentForBiometricSign !== "" && !playAnimation ? (
        <div className="content-block">
          <PdfViewer
            base64Document={ReduxStoreData.documentForBiometricSign}
            signatureFields={signatureFields}
            setData={setData}
          />
          <CheckboxAgreement
            variable={isCheckboxChecked}
            setVariable={setIsCheckboxChecked}
          />
          <Footer>
            <IButton
              text="Powrót do menu"
              color="WHITE"
              onClick={onHandelBack}
            />
            {/* <IButton
              text="Dodaj podpis"
              color="WHITE"
              onClick={handelSignature}
            /> */}
            <IButton
              text="Wyślij dokument"
              color="WHITE"
              onClick={onHandelSendDocument}
              disabled={!isCheckboxChecked}
            />
            <IButton
              text="Wyczyść"
              color="WHITE"
              onClick={() => data.clearCanvas()}
            />
          </Footer>
        </div>
      ) : (
        <div className="upload-file-block">
          <DragAndDropUploaderForOne pdfFilesUploaded={onPdfFilesUploaded} />
          <div className="footer">
            <IButton
              text="Powrót do menu"
              color="WHITE"
              onClick={onHandelBack}
            />
          </div>
        </div>
      )}
      {showDocumentSentModal && (
        <DocumentSentModal
          {...{ success: documentSentSuccess, setShowDocumentSentModal }}
        />
      )}
      {playAnimation && (
        <SigningAnimation
          playAnimation={playAnimation}
          setPlayAnimation={setPlayAnimation}
          endFunction={endAnimationFunction}
        />
      )}
    </div>
  );
};
