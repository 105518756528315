import { useState, useEffect } from 'react';

const getDocumentPages = async ({ scale = 2, url }) => {
  const PDFJS = window.pdfjsLib;

  const loadingTask = PDFJS.getDocument({ data: url });
  const pdf = await loadingTask.promise;

  const { numPages } = pdf;

  const canvasURLs = [];

  for (let i = 0; i < numPages; i++) {
    const page = await pdf.getPage(i + 1);

    const viewport = page.getViewport(scale);
    const { width, height } = viewport;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.className = 'page';
    await page.render({
      canvasContext: canvas.getContext('2d'),
      viewport,
      renderInteractiveForms: true,
    });

    canvasURLs.push(canvas.toDataURL());
  }

  return canvasURLs;
};

export const useDocument = (base64) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const getPages = async () => {
      const correctBase64 = base64.replace('data:application/pdf;base64,', '');
      const canvases = await getDocumentPages({
        url: atob(correctBase64),
      });
      setPages(canvases);
    };

    if (base64) {
      getPages();
    }
  }, [base64]);
  return {
    pages,
  };
};
