import { Crypt, RSA } from "hybrid-crypto-js";
import { uniqWith, isEqual } from "lodash";

export default class BiometricDataHandler {
  constructor(deviceGuid, documentId, imgPages, boundaries) {
    this.boundaries = boundaries;
    this.imgPages = imgPages;
    this.publicKey = localStorage.getItem("documentsPublicKey");
    this.deviceGuid = deviceGuid;
    this.documentId = documentId;
    this._textLayers = [];
    this._pointerEvents = [
      "pointerdown",
      "pointerup",
      "pointercancel",
      "pointermove",
      "pointerover",
      "pointerout",
      "pointerenter",
      "pointerleave",
      "gotpointercapture",
      "lostpointercapture",
    ];
    this.strokesOnPages = [];
    this.isClean = true;
    this.obj = {
      startFilling: new Date().toISOString(),
      endFilling: null,
      zeroIsTop: true,
      pages: [],
      pagesWithDetails: [],
      inputs: [],
    };
    this.pts = [];
    this.memcnv = "";
    this.memctx = "";
    this.createmem = true;
    this._drawByFinger = false;
    this._scale = 1;
    this._penButtonTypes = {
      tip: 0x1, // left mouse, touch contact, pen contact
      barrel: 0x2, // right mouse, pen barrel button
      middle: 0x4, // middle mouse
      eraser: 0x20, // pen eraser button
    };
    this._lastPosition = {
      x: 0,
      y: 0,
    };
    this._isDrawing = false;
    this._pages = new Set();
  }

  clearCanvas() {
    this.isClean = true;
    if (this.onChange) this.onChange(true);
    const canvases = document.getElementsByTagName("canvas");

    [...canvases].forEach((canvas) => {
      if (canvas && canvas !== null) {
        const c = canvas.getContext("2d");
        if (Array.isArray(this.imgPages))
          this.imgPages.forEach((page) => {
            const image = new Image();
            image.onload = () => {
              c.drawImage(image, 0, 0);
            };
            image.src = page;
          });
      }
    });

    // const canvas = document.getElementById('canvas-1');
    // if (canvas && canvas !== null) {
    //   const c = canvas.getContext('2d');
    //   if (Array.isArray(this.imgPages))
    //     this.imgPages.forEach((page) => {
    //       const image = new Image();
    //       image.onload = () => {
    //         c.drawImage(image, 0, 0);
    //       };
    //       image.src = page;
    //     });
    // }
  }

  getBiometricObject() {
    const { pagesWithDetails, pages, ...rest } = this.obj;

    const filteredPages = pages.map((obj) => {
      const filteredStrokes = obj.strokes.map((arr) => uniqWith(arr, isEqual));

      return {
        pageNumber: obj.pageNumber,
        strokes: filteredStrokes,
      };
    });

    return {
      ...rest,
      pages: filteredPages,
      biometricData: this.getBiometricData(),
      // pagesWithDetails,
    };
  }

  prepareDataHandler(pagesCount) {
    if (!!this.obj.pages.length) return;

    for (var i = 0; i < pagesCount; i++) {
      this.obj.pages.push({
        pageNumber: i + 1,
        strokes: [],
      });
    }

    for (var j = 0; j < pagesCount; j++) {
      this.obj.pagesWithDetails.push({
        pageNumber: j + 1,
        strokes: [],
      });
    }
  }

  addEventHandlers(pages) {
    pages.forEach((page, index) => {
      this._pages.add(index);
      const canvas = document.querySelector(`#canvas-${index + 1}`);

      if (!canvas) return;

      for (var j = 0; j < this._pointerEvents.length; j++) {
        canvas.addEventListener(this._pointerEvents[j], (evt) =>
          this.onPointerEvent(canvas, evt, index + 1)
        );
      }
    });
  }

  removeEventHandlers(pages) {
    pages.forEach((page, index) => {
      const canvas = document.querySelector(`#canvas-${index + 1}`);

      if (!canvas) return;

      for (var j = 0; j < this._pointerEvents.length; j++) {
        canvas.removeEventListener(this._pointerEvents[j], (evt) =>
          this.onPointerEvent(canvas, evt, index + 1)
        );
      }
    });
  }

  checkIfInsideBoundaries(boundaries, pts) {
    if (!boundaries || !pts) return false;
    const x = pts.x / 2;
    const y = pts.y / 2;
    if (
      boundaries.left < x &&
      boundaries.left + boundaries.width > x &&
      boundaries.bottom > y &&
      boundaries.bottom - boundaries.height < y
    )
      return true;
    return false;
  }

  getClickedBoundary(pos) {
    if (this.boundaries.length === 0) return null;
    const foundSome = this.boundaries.find((boundary) => {
      if (this.checkIfInsideBoundaries(boundary, pos)) return true;
      return false;
    });

    return foundSome;
  }

  hasBoundaries() {
    return this.boundaries.length > 0;
  }

  onPointerEvent(canvas, evt, pageNumber) {
    const pos = {
      x: (evt.offsetX * canvas.width) / parseInt(canvas.offsetWidth),
      y: (evt.offsetY * canvas.width) / parseInt(canvas.offsetWidth),
      p: 1,
    };

    var pressure = evt.pressure;
    var buttons = evt.buttons;

    if (this.createmem) {
      this.memcnv = document.createElement("canvas");
      this.memcnv.dataset.pageNumber = pageNumber;
      this.memcnv.width = canvas.width;
      this.memcnv.height = canvas.height;
      this.memctx = this.memcnv.getContext("2d");

      this.memctx.imageSmoothingEnabled = true;
      this.memctx.imageSmoothingQuality = "high";
      this.memctx.drawImage(canvas, 0, 0);
      this.createmem = false;
      this.memctx.lineWidth = 1;
      this.memctx.fillStyle = "#aaaa00";
    }
    var context = canvas.getContext("2d");
    context.imageSmoothingEnabled = true;
    context.imageSmoothingQuality = "high";

    if (evt.pointerType) {
      switch (evt.pointerType) {
        case "pen":
          context.strokeStyle = "red";
          context.lineWidth = pressure * this._scale;
          break;
        case "mouse":
          if (pressure > 0) {
            pressure = 1;
            context.strokeStyle = "blue";
            context.lineWidth = pressure * this._scale;
          }
          break;
        case "touch":
          // if (this._drawByFinger && pressure > 0)

          pressure = 1;
          context.strokeStyle = "blue";
          context.lineWidth = pressure * 2;

          break;

        default:
          return;
      }

      var point = {};
      point.x = pos.x / 2;
      point.y = pos.y / 2;
      point.Time = evt.timeStamp;
      point.force = pressure;
      point.Utc = new Date().toISOString();
      //TODO point

      // -1 or ''
      var pageID = canvas.getAttribute("page") - 1;
      // return

      switch (evt.type) {
        case "pointerdown":
          this._isDrawing = true;
          this._lastPosition = pos;
          this.startedOnBoundary = this.getClickedBoundary(pos);

          this.pts.push(pos);
          context.beginPath();
          context.fillStyle = "blue";
          context.fillRect(
            pos.x,
            pos.y,
            context.lineWidth / 2,
            context.lineWidth / 2
          );
          context.stroke();

          if (!this.obj.startFilling) {
            this.obj.startFilling = new Date().toISOString();
          }

          this.obj.pages[pageID].strokes.push([]);
          this.obj.pagesWithDetails[pageID].strokes.push([]);

          this.obj.pages[pageID].strokes[
            this.obj.pages[pageID].strokes.length - 1
          ].push({ x: point.x, y: point.y });

          this.obj.pagesWithDetails[pageID].strokes[
            this.obj.pagesWithDetails[pageID].strokes.length - 1
          ].push({ ...point, pageNumber: pageID });

          this.obj.endFilling = new Date().toISOString();
          break;

        case "pointerup":
          // showTextLayer();
          this.pts = [];
          this.memctx.clearRect(0, 0, canvas.width, canvas.height);
          this.memctx.drawImage(canvas, 0, 0);
          this.createmem = true;
          this._isDrawing = false;
          this.startedOnBoundary = null;

          break;

        case "pointermove":
          if (
            this.hasBoundaries() &&
            !this.checkIfInsideBoundaries(this.startedOnBoundary, pos)
          )
            return;

          if (!this._isDrawing || buttons === this._penButtonTypes.eraser) {
            return;
          } else if (pressure > 0) {
            this.isClean = false;
            if (this.onChange) this.onChange(false);
            this.pts.push(pos);
            if (this.pts.length === 4) {
              if (this.boundaries.length > 0) {
                for (const j in this.pts) {
                  if (
                    this.checkIfInsideBoundaries(
                      this.startedOnBoundary,
                      this.pts[j]
                    )
                  )
                    this.drawBezier(this.pts, this.memctx);
                }
              } else if (this.boundaries.length === 0)
                this.drawBezier(this.pts, this.memctx);

              // this.drawBezier(this.pts, this.memctx);
              var pt = this.pts.pop();
              this.pts = [];
              this.pts.push(pt);
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(this.memcnv, 0, 0);
            } else {
              if (
                this.hasBoundaries() &&
                !this.checkIfInsideBoundaries(
                  this.startedOnBoundary,
                  this._lastPosition
                )
              )
                return;

              context.beginPath();
              context.lineCap = "round";
              context.lineJoin = "round";
              context.moveTo(this._lastPosition.x, this._lastPosition.y);
              var line = this.createGrowingLine(
                this._lastPosition.x,
                this._lastPosition.y,
                pos.x,
                pos.y,
                this._lastPosition.p,
                pos.p
              );
              context.fillStyle = "blue";
              context.fill(line);
              context.stroke();
            }

            this.obj.pages[pageID].strokes[
              this.obj.pages[pageID].strokes.length - 1
            ].push({ x: point.x, y: point.y });
            this.obj.pagesWithDetails[pageID].strokes[
              this.obj.pagesWithDetails[pageID].strokes.length - 1
            ].push({ ...point, pageNumber: pageID });
          }
          this._lastPosition = pos;
          break;

        case "pointerenter":
          this.createmem = true;
          this.startedOnBoundary = null;
          if (this._isDrawing && pressure > 0) {
            this._lastPosition = pos;
            this.pts.push(pos);
            context.beginPath();
            context.fillStyle = "blue";

            context.fillRect(
              pos.x,
              pos.y,
              context.lineWidth / 2,
              context.lineWidth / 2
            );
            context.stroke();
          } else if (pressure === 0) {
            this._isDrawing = false;
          }
          document.body.style.cursor = "crosshair";

          break;

        case "pointerleave":
          this.startedOnBoundary = null;
          this.pts = [];
          this.memctx.clearRect(0, 0, canvas.width, canvas.height);
          this.memctx.drawImage(canvas, 0, 0);
          this.createmem = true;
          // showTextLayer();
          document.body.style.cursor = "default";
          break;

        default:
          break;
      }
    }
  }

  createGrowingLine(x1, y1, x2, y2, startWidth, endWidth) {
    // calculate direction vector of point 1 and 2
    const directionVectorX = x2 - x1,
      directionVectorY = y2 - y1;
    // calculate angle of perpendicular vector
    const perpendicularVectorAngle =
      Math.atan2(directionVectorY, directionVectorX) + Math.PI / 2;
    // construct shape
    const path = new Path2D();
    path.arc(
      x1,
      y1,
      startWidth / 2,
      perpendicularVectorAngle,
      perpendicularVectorAngle + Math.PI
    );
    path.arc(
      x2,
      y2,
      endWidth / 2,
      perpendicularVectorAngle + Math.PI,
      perpendicularVectorAngle
    );
    path.closePath();
    return path;
  }

  drawBezier(pts, ctx) {
    ctx.beginPath();
    ctx.strokeStyle = "blue";
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.moveTo(pts[0].x, pts[0].y);

    var t = 1;
    // Draws Bezier curve from context position to midPoint.
    for (var i = 0; i < pts.length - 1; i++) {
      ctx.lineWidth = pts[i].p;

      var p0 = i > 0 ? pts[i - 1] : pts[0];
      var p1 = pts[i];
      var p2 = pts[i + 1];
      var p3 = i !== pts.length - 2 ? pts[i + 2] : p2;

      var cp1x = p1.x + ((p2.x - p0.x) / 6) * t;
      var cp1y = p1.y + ((p2.y - p0.y) / 6) * t;

      var cp2x = p2.x - ((p3.x - p1.x) / 6) * t;
      var cp2y = p2.y - ((p3.y - p1.y) / 6) * t;

      ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
      // This lineTo call eliminates gaps (but leaves flat lines if stroke
      // is fast enough).
      ctx.stroke();
    }
  }

  getBiometricData() {
    const strokesWithDetails = this.obj.pagesWithDetails;

    const signatureRegions = [];
    if (this.boundaries.length > 0) {
      this.boundaries.forEach((e) => {
        signatureRegions.push({
          x: e.left,
          y: e.bottom - e.height,
          width: e.width,
          height: e.height,
          page: e.pageNumber,
          id: e.fieldName,
        });
      });
    } else {
      this.obj.pages.forEach((e, index) => {
        signatureRegions.push({
          x: 0,
          y: 0,
          width: 100000,
          height: 100000,
          page: index + 1,
          id: "mockId",
        });
      });
    }
    let regionsArray = [];
    regionsArray = signatureRegions.map(({ x, y, width, height, page, id }) => {
      const dpiMultiplier = 1;
      const inputPlacement = {
        left: x * dpiMultiplier,
        top: y * dpiMultiplier,
        right: (x + width) * dpiMultiplier,
        bottom: (y + height) * dpiMultiplier,
        page: page - 1,
        id: id,
      };
      const filteredStrokesWithDetails = strokesWithDetails
        .map(({ strokes }) => {
          return strokes
            .map((strokeArray) =>
              strokeArray.reduce(
                (acc, stroke, index) => {
                  if (index === 0) {
                    acc.strokeBegining = stroke.Utc;
                  }
                  if (
                    stroke.x > inputPlacement.left &&
                    stroke.x < inputPlacement.right &&
                    stroke.y > inputPlacement.top &&
                    stroke.y < inputPlacement.bottom
                  ) {
                    const prevIndex =
                      acc?.points && acc?.points.length > 1
                        ? acc.points.length - 1
                        : 0;
                    acc.points.push({
                      x: stroke.x,
                      y: stroke.y,
                      force: stroke.force,
                      Time: stroke.Time,
                      delta: stroke.Time - acc.points[prevIndex]?.Time || 0,
                    });
                  }
                  return acc;
                },
                {
                  strokeBegining: "",
                  points: [],
                }
              )
            )
            .filter((a) => a.points.length);
        })
        .filter((x) => x.length)
        .flat();

      const biometricContent = {
        documentId: this.documentId,
        deviceGuid: this.deviceGuid,
        region_id: id,
        strokes: filteredStrokesWithDetails,
        acquireTime:
          filteredStrokesWithDetails.length > 0
            ? filteredStrokesWithDetails.slice(-1)[0].points.slice(-1)[0].Time
            : null,
      };

      const refinedStrokes = filteredStrokesWithDetails;

      if (refinedStrokes.length > 0) {
        for (const i in refinedStrokes) {
          for (const j in refinedStrokes[i].points) {
            delete refinedStrokes[i].points[j].Time;
          }
        }
      }

      const encryptedBiometricContent = this.encryptBiometricData(
        biometricContent,
        this.publicKey
      );
      return {
        id: id,
        pageNumber: inputPlacement.page,
        top: inputPlacement.top,
        left: inputPlacement.left,
        width: width * dpiMultiplier,
        height: height * dpiMultiplier,
        strokes: refinedStrokes,
        biometric: {
          contentBase64: encryptedBiometricContent.content,
          keyBase64: encryptedBiometricContent.key,
          iv: encryptedBiometricContent.iv,
        },
      };
    });

    return {
      algorithm: "RSA-OAEP & AES-CFB+IV[16]",
      regions: regionsArray,
    };
  }

  encryptBiometricData(biometricData, key) {
    const stringifyBiometricData = JSON.stringify(biometricData);

    const crypt = new Crypt({
      aesStandard: "AES-ECB",
      rsaStandard: "RSA-OAEP",
      aesIvSize: 16,
      md: "sha1",
    });

    const encrypted = crypt.encrypt(key, stringifyBiometricData);
    const encyptedObj = JSON.parse(encrypted);
    const { cipher, keys, iv } = encyptedObj;

    const a = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));
    const b = Uint8Array.from(atob(Object.values(keys)[0]), (c) =>
      c.charCodeAt(0)
    );

    const ab = [...a, ...b];
    var base64String = btoa(
      String.fromCharCode.apply(null, new Uint8Array(ab))
    );

    const objData = {
      content: cipher,
      key: base64String,
      iv: iv,
    };

    return objData;
  }

  setPublicKey(key) {
    this.publicKey = key;
  }

  setOnChange(onChange) {
    this.onChange = onChange;
  }
}
