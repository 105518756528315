import { RestService } from './RestService';

class DocumentsService {
  async getDocuments() {
    const { data } = await RestService.api.get(`/signing`);
    return data;
  }

  async getDocumentById(selectedDocumentId) {
    const { data } = await RestService.api.get(
      `/documents/${selectedDocumentId}`
    );
    return data;
  }

  async getDocumentEncryptionKey() {
    const { data } = await RestService.api.get('/encryption/publickey');
    return data;
  }

  ///SignaturesChart GET (2)
  async getPartOfSignature() {
    const { data } = await RestService.api.get(`/signaturesCharts`);
    return data;
  }

  async getDocumentTemplate() {
    const { data } = await RestService.api.get('/signaturesCharts/chart');
    return data;
  }

  async getDocumentBase64Template() {
    const { data } = await RestService.api.get(
      '/signaturesCharts/chartB64withCoords'
    );
    return data;
  }

  async getIdentificationBase64Document() {
    const { data } = await RestService.api.get(
      '/identification/fileB64withCoords'
    );
    return data;
  }

  async getVerificationBase64Document() {
    const { data } = await RestService.api.get(
      '/verification/fileB64withCoords'
    );
    return data;
  }

  async createDocumentWithFile(param) {
    const { data } = await RestService.api.post('/documents/create', {
      ...param,
    });
    return data;
  }

  ///SignaturesChart POST(1)
  async createNewDocumentWithSignatures(param) {
    const { data } = await RestService.api.post(`/signaturesCharts/`, param);
    return data;
  }

  ///SignaturesChart PUT (1)
  async updateNewDocumentWithSignatures(param) {
    const { data } = await RestService.api.put(`/signaturesCharts/`, param);
    return data;
  }

  //SignDocument POST (5)
  async signDocumentWithBiometricData(param) {
    const { data } = await RestService.api.post(`/signing/sign`, {
      ...param,
    });
    return data;
  }

  //BiometricIdentification(4)
  async biometricIdentification(param) {
    const { data } = await RestService.api.post(
      `/identification/identify`,
      param
    );
    return data;
  }

  //BiometricVerification (3)
  async biometricVerification(param, userGuid) {
    const { data } = await RestService.api.post(
      `/verification/${userGuid}`,
      param.signaturesData
    );
    return data;
  }

  async getDocumentSigningFields(content) {
    const { data } = await RestService.api.post('/utility/pdfCoords', content);
    return data;
  }

  async checkIfSignaturesChartExists() {
    const { data } = await RestService.api.get('/signaturesCharts/exists');
    return data;
  }
}

const documentsService = new DocumentsService();

export { documentsService };
