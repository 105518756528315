import React from "react";
import ContentLoader from "react-content-loader";

const FIELD_HEIGHT = 160;
const FIELD_WIDTH = 430;
const RX = "5";
const RY = "5";

const FIELDS = Array(6).fill(0);

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={1350}
    viewBox="0 0 900 1350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="100" rx={RX} ry={RY} width="100%" height="60" />
    <rect x="20%" y="200" rx={RX} ry={RY} width="60%" height="60" />
    {/* SIGN FIELDS */}
    {FIELDS.map((e, i) => {
      return (
        <rect
          key={i}
          x={i < 3 ? "3%" : "53%"}
          y={350 + (i % 3) * (FIELD_HEIGHT + 30)}
          rx={RX}
          ry={RY}
          width={FIELD_WIDTH}
          height={FIELD_HEIGHT}
        />
      );
    })}
    <rect x="0" y="950" rx={RX} ry={RY} width="100%" height="150" />
  </ContentLoader>
);

export default MyLoader;
