import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const Modal = ({
  open,
  setDialogVisibility,
  getToHomePage,
  goToNextDocument,
  text,
  title,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const onHandelGoToNextDocument = async () => {
    await goToNextDocument();
    setDialogVisibility(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setDialogVisibility(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={getToHomePage} color="primary">
            Wroc na ekran glowny
          </Button>
          {goToNextDocument !== false && (
            <Button
              onClick={onHandelGoToNextDocument}
              color="primary"
              autoFocus
            >
              Przejdz do kolejnego
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
