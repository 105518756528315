import { useState } from "react";
import "./checkboxAgreement.scss";

const CheckboxAgreement = ({ variable, setVariable }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className="checkbox">
      <button
        onClick={() => {
          setIsClicked(!isClicked);
          setVariable(!variable);
        }}
      >
        <div style={{ backgroundColor: isClicked ? "#000" : "" }}></div>
      </button>
      <p>
        Zgadzam się na przetwarzanie moich danych osobowych (wzoru podpisu
        biometrycznego) na potrzeby projektu realizowanego w ramach umowy
        POIR.01.01.01-00-0639/19-00 o dofinansowanie Projektu: Opracowanie
        innowacyjnego systemu weryfikacji tożsamości i weryfikacji podpisów na
        podstawie behawioralnego podpisu biometrycznego w ramach Poddziałania
        1.1.1 Programu Operacyjnego Inteligentny Rozwój 2014-2020
        współfinansowanego ze środków Europejskiego Funduszu Rozwoju
        Regionalnego.
      </p>
    </div>
  );
};

export default CheckboxAgreement;
