import React from "react";
import ReactPlayer from "react-player";
import "./signingAnimation.scss";

export const SigningAnimation = ({
  playAnimation,
  setPlayAnimation,
  endFunction,
}) => {
  return (
    <div className="signingAnimation">
      <ReactPlayer
        url="podpis-animacja.mp4"
        playing={playAnimation}
        width="100%"
        height="80%"
        onEnded={() => {
          setPlayAnimation(false);

          if (endFunction) endFunction();
        }}
      ></ReactPlayer>
    </div>
  );
};
