import { IButton } from "app/components/common/Button.jsx";
import { Footer } from "app/components/common/Footer/Footer.jsx";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { documentsState } from "../../../store/documents";
import {
  getDocumentById,
  getDocumentsSigningFields,
} from "../../../store/documents/documents.thunks";
import { userData } from "store/account";
import BiometricDataHandler from "../../services/biometricDataHandler";
import { PdfViewer } from "../common/PdfViewer";
import { Modal } from "../common/modal-window/Modal";
import LoadingModal from "../common/LoadingModal/LoadingModal";
import { signDocumentWithBiometricData } from "../../../store/documents";
import DocumentSentModal from "./cardCreatorPage/DocumentSentModal";
import useAuth from "../common/useAuth";

export const DocumentPreviewPage = () => {
  useAuth();
  const [data, setData] = useState("");
  const [signatureFields, setSignatureFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentSentSuccess, setDocumentSentSuccess] = useState(false);
  const [showDocumentSentModal, setShowDocumentSentModal] = useState(false);
  const [createdDocumentId, setCreatedDocumentId] = useState("");
  const dispatch = useDispatch();
  const ReduxStoreData = useSelector(documentsState);
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector(userData);
  const bdh = new BiometricDataHandler();

  const [isDialogVisible, setDialogVisibility] = useState(false);

  const onHandelSendDocument = async () => {
    const documentData = ReduxStoreData.documents.documentsForSigning.find(
      (e) => e.documentId === ReduxStoreData.selectedDocument.documentGuid
    );
    const bdhObject = data.obj;
    const biometricData = data.getBiometricData();
    const filteredRegions = biometricData.regions.filter((e, index) => {
      if (e.strokes.length > 0) {
        const filteredStrokes = e.strokes.map((stroke) => {
          return stroke.points;
        });
        e.strokes = filteredStrokes;
        return true;
      }
      return false;
    });

    const filteredPages = bdhObject.pages.filter((e) => {
      if (e.strokes.length === 0) return false;
      return true;
    });
    biometricData.regions = filteredRegions;
    bdhObject.pages = filteredPages;
    const objectToSend = {
      documentId: documentData.presentationInfo.documentId,
      presentationId: documentData.presentationInfo.presentationId,
      userId: user.sub,
      startFilling: bdhObject.startFilling,
      endFilling: bdhObject.endFilling,
      zeroIsTop: bdhObject.zeroIsTop,
      dpi: 72,
      pages: bdhObject.pages,
      inputs: bdhObject.inputs,
      biometricData: {
        ...biometricData,
      },
    };
    setIsLoading(true);
    const res = await dispatch(signDocumentWithBiometricData(objectToSend));
    if (res) {
      setIsLoading(false);
      if (res.payload === "") setDocumentSentSuccess(true);
      else setDocumentSentSuccess(false);
      setShowDocumentSentModal(true);
    }
  };

  const getDocumentSignaturesFields = async () => {
    const signatureFieldsRes = await dispatch(
      getDocumentsSigningFields(ReduxStoreData.selectedDocument.documentContent)
    );
    if (signatureFieldsRes) {
      const signatures = [];
      signatureFieldsRes.payload.extractedSignatures.forEach((e, index) => {
        const { left, width, height, fieldName, pageNumber } = e;
        signatures.push({
          bottom: signatureFieldsRes.payload.height - e.bottom,
          left,
          width,
          height,
          fieldName,
          pageNumber,
        });
      });
      setSignatureFields(signatures);
    }
  };

  const getDocumentId = async () => {
    setIsLoading(true);
    const response = await dispatch(getDocumentById(id));
    if (response) {
      setCreatedDocumentId(response.payload.documentGuid);
      const signatureFieldsRes = await dispatch(
        getDocumentsSigningFields(response.payload.documentContent)
      );
      if (signatureFieldsRes) {
        const signatures = [];
        signatureFieldsRes.payload.extractedSignatures.forEach((e, index) => {
          const { left, width, height, fieldName, pageNumber } = e;
          signatures.push({
            bottom: signatureFieldsRes.payload.height - e.bottom,
            left,
            width,
            height,
            fieldName,
            pageNumber,
          });
        });
        setSignatureFields(signatures);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDocumentId();
    getDocumentSignaturesFields();
  }, []);

  return (
    <div className="content-block">
      {isLoading && <LoadingModal />}
      <PdfViewer
        base64Document={ReduxStoreData.selectedDocument.documentContent}
        signatureFields={signatureFields}
        setData={setData}
      />
      <Footer>
        <IButton text="Powrot do menu" onClick={() => history.push("/")} />
        <IButton text="Wyślij dokument" onClick={onHandelSendDocument} />
        <IButton
          text="Wyczyść"
          color="WHITE"
          onClick={() => data.clearCanvas()}
        />
        {/* <IButton
          className="mr-4"
          text="Get biometric object"
          onClick={() => {
            console.log(bdh.getBiometricObject());
          }}
        /> */}
      </Footer>

      {isDialogVisible ? (
        <Modal
          open={isDialogVisible}
          setDialogVisibility={setDialogVisibility}
          getToHomePage={() => history.push("/")}
          goToNextDocument={false}
          title={`Success `}
          text={`Pomyślnie podpisano dokument.`}
        />
      ) : null}
      {showDocumentSentModal && (
        <DocumentSentModal
          {...{ success: documentSentSuccess, setShowDocumentSentModal }}
        />
      )}
    </div>
  );
};
