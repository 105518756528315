import React from 'react';
import './userVerificationPage.scss';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';

export const UserVerificationResult = ({ success, setShowResultModal }) => {
  return (
    <div className="verificationResult">
      <div className="verificationResult__content">
        {success && (
          <div>
            <TiTick
              style={{
                color: '#02a10c',
                width: '200px',
                height: '200px',
                margin: '0 auto',
              }}
            />
            <p style={{ color: '#02a10c' }}>
              Udało się zweryfikować użytkownika
            </p>
          </div>
        )}
        {!success && (
          <div>
            <ImCross
              style={{
                color: '#eb1b00',
                width: '100px',
                height: '100px',
                margin: '0 auto',
              }}
            />
            <p style={{ color: '#eb1b00' }}>
              Nie udało się zweryfikować użytkownika
            </p>
          </div>
        )}
        <button onClick={() => setShowResultModal(false)}>Zakończ</button>
      </div>
    </div>
  );
};
