import { oidcSettings } from "app/config/oidcSettings";
import axios from "axios";

class RestService {
  constructor(authService, baseURL) {
    this.authService = authService;
    this.api = axios.create({
      baseURL,
    });
    this.interceptRequest();
    this.interceptResponse();
  }

  interceptRequest() {
    // Request interceptor for API calls
    this.api.interceptors.request.use(
      async (config) => {
        const accessToken = await sessionStorage.getItem("access_token");
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
  interceptResponse() {
    // Response interceptor for API calls
    this.api.interceptors.response.use(
      async (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          const storageData = sessionStorage.getItem(
            `oidc.user:${oidcSettings.authority}:${oidcSettings.client_id}`
          );

          if (!storageData) this.authService.redirect();

          const oidcData = JSON.parse(storageData);
          const expired = oidcData.expires_at < Date.now();

          if (expired) {
            await this.authService.signOut();
          }
        }
        return Promise.reject(error);
      }
    );
  }
}

export { RestService };
