import * as React from 'react';

export const IButton = ({
  text,
  onClick,
  color = 'WHITE',
  className,
  disabled,
}) => {
  const BUTTONS_VARIANTS = {
    WHITE:
      ' border-gray-300 bg-white text-base text-gray-700 hover:bg-gray-50 ',
    INDIGO: 'bg-indigo-600 text-base text-white hover:bg-indigo-700 ',
  };

  return (
    <button
      onClick={onClick}
      className={
        'inline-flex justify-center rounded-md border shadow-sm px-4 py-2 font-medium sm:mt-0 sm:w-auto sm:text-sm ' +
        BUTTONS_VARIANTS[color] +
        ' ' +
        className
      }
      style={{
        backgroundColor: disabled ? '#bababa' : '',
        pointerEvents: disabled ? 'none' : '',
      }}
    >
      {text}
    </button>
  );
};
