import React, { useEffect } from "react";

import { Footer } from "app/components/common/Footer/Footer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPartOfSignature } from "../../../../store/documents/documents.thunks";
import { documentsState } from "../../../../store/documents";
import { IButton } from "app/components/common/Button.jsx";
import "./signatureFragmentPage.scss";
import "../../../../styles.scss";
import LoadingContent from "app/components/common/LoadingModal/LoadingContent";
import useAuth from "app/components/common/useAuth";
export const SignatureFragmentPage = () => {
  useAuth();
  const ReduxStoreData = useSelector(documentsState);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPartOfSignature("testID"));
  }, [dispatch]);

  const onHandelBack = () => {
    history.push("/");
  };
  if (ReduxStoreData.signaturesChartLoading)
    return (
      <div className="content-block">
        <LoadingContent />
      </div>
    );
  if (!ReduxStoreData.signaturesChartExists)
    return (
      <div className="content-block">
        <div className="content-wrapper">
          <h2>Brak zarejestrowanego podpisu</h2>
        </div>
      </div>
    );

  console.log(ReduxStoreData);
  return (
    <div className="content-block">
      <div className="content-wrapper">
        <h2>Twój wzór podpisu</h2>
        <div className="image-block">
          {}
          <img
            src={`data:image/svg+xml;base64,${ReduxStoreData.partOfUserSignature.signaturesPart}`}
            alt="partOfSignature"
            className="bg-zinc-100	"
          />
        </div>
      </div>

      <Footer>
        <IButton text="Powrót do menu" color="WHITE" onClick={onHandelBack} />
      </Footer>
    </div>
  );
};
