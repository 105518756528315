import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  checkIfSignaturesChartExists,
  getDocumentEncryptionKey,
} from "store/documents";

export const useAuth = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const getPublicKey = async () => {
    const { payload } = await dispatch(getDocumentEncryptionKey());
    if (payload) localStorage.setItem("documentsPublicKey", payload);
  };

  const checkSignaturesChart = async () => {
    const res = await dispatch(checkIfSignaturesChartExists());
  };

  useEffect(() => {
    (async () => {
      if (!sessionStorage.getItem("access_token")) return;
      await getPublicKey();
      await checkSignaturesChart();
      setLoaded(true);
    })();
  }, []);

  return loaded;
};

export default useAuth;
