import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './account/account.reducer';
import documentsReducer from './documents/documents.reducer';


export default configureStore({
  reducer: {
    account: accountReducer,
    documents: documentsReducer,
    
  },
});
