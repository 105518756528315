import { createSlice } from '@reduxjs/toolkit';

import { getUser } from './account.thunks';

export const accountSlice = createSlice({
  name: '[ACCOUNT]',
  initialState: {
    isLoggedIn: null,
    access_token: null,
    userData: [],
  },
  reducers: {
    SET_USER: (state, { payload }) => {
      state.isLoggedIn = true;
      state.access_token = payload;
      state.userData = payload;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { payload }) => {
      state.isLoggedIn = !!payload;
    },
  },
});

export const { SET_USER: setUser } = accountSlice.actions;

export default accountSlice.reducer;
