import React from 'react';
import './footer.scss';

export const Footer = ({ children }) => {
  return (
    <div className="footer">
      {/* <IButton text='Powrót do menu' color='WHITE' onClick={onHandelBack} />
        <IButton text='Dodaj podpis' color='WHITE' onClick={handelSignature} />

        {`Podpisy: ${ReduxStoreData.signaturesOnDocument && ReduxStoreData.signaturesOnDocument.length}`}
        <IButton text='Wyślij dokument' color='WHITE' onClick={() => console.log('DATE to SEND')} /> */}
      {children}
    </div>
  );
};
