import React from 'react';
import './DocumentCard.scss';

export const DocumentCard = ({ logo, data }) => {
  return (
    <div className="document-card">
      <div className="document-card-image">
        {' '}
        <img src={logo} alt={logo} />{' '}
      </div>
      <div className="document-card-text">
        <p>{data.documentName ? data.documentName : data.groupName}</p>
        <span>{data.utcTimeOfCreation}</span>
      </div>
    </div>
  );
};
