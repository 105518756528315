import { IButton } from "app/components/common/Button.jsx";
import { Footer } from "app/components/common/Footer/Footer.jsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createNewDocumentWithSignatures,
  updateNewDocumentWithSignatures,
} from "../../../../store/documents/documents.thunks";
import { PdfViewer } from "../../common/PdfViewer";
import { documentsService } from "../../../services/documentsService";
import DocumentSentModal from "./DocumentSentModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingModal from "app/components/common/LoadingModal/LoadingModal";
import { AiOutlineClose } from "react-icons/ai";
import Skeleton from "./Skeleton";
import CheckboxAgreement from "app/components/common/CheckboxAgreement/CheckboxAgreement";
import useAuth from "app/components/common/useAuth";

const COL_1_X = 105;
const COL_2_X = 475;
const SIGN_WIDTH = 350;
const SIGN_HEIGHT = 130;

const isContaining = (pX, pY, left, bottom, width, height) => {
  // console.log({
  //   pX,
  //   pY,
  //   left,
  //   bottom: bottom,
  //   top: bottom - height,
  //   right: left + width,
  // });
  return (
    pX > left && pX < left + width && pY <= bottom && pY >= bottom - height
  );
};

// get scale from min width and height to max width and height

const WINDOW_WIDTH = window.innerWidth;

const getScale = () => {
  const fieldWidth = 300;
  return WINDOW_WIDTH / fieldWidth - 0.5;
};

var clickTimer = null;

const doubleClick = (onDouble) => (e) => {
  if (clickTimer == null) {
    clickTimer = setTimeout(function () {
      clickTimer = null;
    }, 500);
  } else {
    clearTimeout(clickTimer);
    clickTimer = null;
    if (onDouble) onDouble(e);
  }
};

export const CardCreatorPage = () => {
  useAuth();
  const [template, setTemplate] = useState("");
  const [signatureFields, setSignatureFields] = useState([]);
  const [showDocumentSentModal, setShowDocumentSentModal] = useState(false);
  const [documentSentSuccess, setDocumentSentSuccess] = useState(false);
  const [documentSentFailMessage, setDocumentSentFailMessage] = useState("");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentOffset, setCurrentOffset] = useState({ x: 0, y: 0 });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const onHandelCreateNewDocument = async (data, update = false) => {
    if (!checkStrokes()) {
      setDocumentSentSuccess(false);
      setDocumentSentFailMessage(
        "Wszystkie pola podpisu muszą być wypełnione."
      );
      setShowDocumentSentModal(true);
      return;
    }

    const biometricData = data.getBiometricData();

    const signaturesData = biometricData.regions.map((e, index) => {
      return {
        signatureElementName: `signature_${index.toString()}_${generateRandomString(
          10
        )}`,
        encryptedBiometry: {
          encryptedSignatureB64: JSON.stringify({
            signatureGuid: generateRandomString(10),
            strokes: e.strokes,
            strokesDpi: 36,
            acquireTime: e.strokes[e.strokes.length - 1].strokeBegining,
          }),
          passwordB64: biometricData.regions[index].biometric.keyBase64,
          certiaficteThumbrintB64: data.publicKey,
        },
      };
    });

    const parsedSignatures = signaturesData.map((e, index) => {
      const encryptedSignatures = data.encryptBiometricData(
        JSON.parse(e.encryptedBiometry.encryptedSignatureB64),
        data.publicKey
      );
      return {
        ...e,
        encryptedBiometry: {
          encryptedSignatureB64: encryptedSignatures.content,
          passwordB64: encryptedSignatures.key,
          certificateThumbrintB64: data.publicKey,
        },
      };
    });

    const parsedData = {
      utcTimeOfGathering: new Date().toISOString(),
      topIsZeroForCoordinatesInEncryptedBiometry: true,
      signaturesData: parsedSignatures,
    };

    if (!update) {
      setIsLoading(true);
      const res = await dispatch(createNewDocumentWithSignatures(parsedData));
      if (res) {
        setIsLoading(false);

        if (res.error) {
          setDocumentSentFailMessage("");
          setDocumentSentSuccess(false);
        } else {
          setDocumentSentSuccess(true);
        }
      }
    } else {
      dispatch(updateNewDocumentWithSignatures(parsedData));
    }
    setShowDocumentSentModal(true);
  };

  const handleRemoveStorkes = () => {
    data.clearCanvas();
  };

  const checkStrokes = () => {
    let result = true;
    data.getBiometricData().regions.forEach((e) => {
      if (e.strokes.length === 0) result = false;
    });
    return result;
  };

  // const onHandelUpdateDocument = (data) => {
  //   dispatch(updateNewDocumentWithSignatures(data));
  // };

  useEffect(() => {
    documentsService.getDocumentBase64Template().then((res) => {
      setTemplate(res.signaturesChartBase64);
      const signatures = [];
      res.signatures.forEach((e) => {
        const { left, width, height, fieldName } = e;
        signatures.push({
          bottom: res.heightOfSignaturesChart - e.bottom,
          pageNumber: e.signaturePageLocation,
          left,
          width,
          height,
          fieldName,
        });
      });
      // const signatures = res.signatures.map((e, i) => {
      //   const { left, width, height } = e;
      //   console.log(e);
      //   signatures.push({
      //     bottom: res.heightOfSignaturesChart - e.bottom,
      //     left,
      //     width,
      //     height,
      //   });
      // return {
      //   ...e,
      //   top: 360 + (i % 3) * 220,
      //   left: i < 3 ? COL_1_X : COL_2_X,
      //   width: SIGN_WIDTH,
      //   height: SIGN_HEIGHT,
      //   index: i,
      // };

      setSignatureFields(signatures);
    });
  }, []);
  const isZoomed = currentOffset.x !== 0;

  const onDoubleClick = (e) => {
    const canvas = document.querySelector("#canvas-1");
    const rect = containerRef.current.getBoundingClientRect();

    const px = e.clientX - rect.left;
    const py = e.clientY - rect.top;

    const canvasScale = canvas.width / canvas.offsetWidth;
    const x = px * canvasScale;
    const y = py * canvasScale;

    const found = signatureFields.find((item) => {
      return isContaining(
        x / 2,
        y / 2,
        item.left,
        item.bottom,
        item.width,
        item.height
      );
    });

    if (found) {
      const fieldTop = found.bottom - found.height;
      const cx = (2 * found.left * canvas.offsetWidth) / canvas.width;
      const cy = (2 * fieldTop * canvas.offsetWidth) / canvas.width;

      setCurrentOffset({
        x: rect.x + cx,
        y: rect.y + cy,
        canvasX: cx,
        canvasY: cy,
      });
    }
  };

  const SCALE = getScale();
  const scale = isZoomed ? SCALE : 1;
  const containerRef = useRef(null);
  const cx = currentOffset ? currentOffset.canvasX : 0;
  const cy = currentOffset ? currentOffset.canvasY : 0;

  return (
    <div className="content-block">
      {isLoading && <LoadingModal />}

      <div style={{ minHeight: "1500px" }}>
        <div
          style={
            isZoomed
              ? {
                  position: "fixed",
                  transformOrigin: `${cx * 0.9}px ${cy * 0.9}px`,
                  transform: `translate(-${currentOffset.x}px , -${
                    window.scrollY + currentOffset.y
                  }px) scale(${scale})`,
                  // top: `${currentOffset.y * scale}px`,
                  // left: `${currentOffset.x * scale}px`,
                  // transform: `scale(${scale})`,
                  // transition: "transform left top 0.5s",
                }
              : { position: "relative" }
          }
        >
          <div
            style={{ position: "relative" }}
            ref={containerRef}
            onClick={doubleClick(onDoubleClick)}
          >
            <PdfViewer
              setData={setData}
              base64Document={"data:application/pdf;base64," + template}
              signatureFields={signatureFields}
              skeletonLoadingComponent={<Skeleton />}
            />
            {/* Naniesienie linkow z pdfa  */}
            <a
              href="https://icsign.pl/nagrody"
              style={{
                width: "13%",
                height: "1.5%",
                position: "absolute",
                top: "77%",
                left: "51%",
              }}
            ></a>
            <a
              href="https://icsign.pl/nagrody/regulamin"
              style={{
                width: "25%",
                height: "1.5%",
                position: "absolute",
                top: "80%",
                left: "55%",
              }}
            ></a>
          </div>
        </div>
      </div>
      <CheckboxAgreement
        variable={isCheckboxChecked}
        setVariable={setIsCheckboxChecked}
      />
      <Footer>
        <IButton
          text="Powrót do menu"
          color="WHITE"
          onClick={() => history.push("/")}
        />
        <IButton
          text="Wyślij dokument"
          color="WHITE"
          onClick={() => onHandelCreateNewDocument(data)}
          disabled={!isCheckboxChecked}
        />

        {/* <IButton
          text="Zaktualizuj dokument"
          color="WHITE"
          // onClick={() => onHandelCreateNewDocument(data, true)}
          onClick={() => console.log(checkStrokes())}
        /> */}
        <IButton
          text="Wyczyść"
          color="WHITE"
          onClick={() => handleRemoveStorkes()}
        />
      </Footer>
      <div
        className="close-zoom-header"
        style={{
          opacity: isZoomed ? 1 : 0,
          visibility: isZoomed ? "visible" : "hidden",
        }}
      >
        {/* button with close icon */}
        <button
          className="close-zoom-header-button"
          onClick={() => {
            setCurrentOffset({ x: 0, y: 0 });
          }}
        >
          <AiOutlineClose size={30} color="white" />
        </button>
      </div>
      {showDocumentSentModal && (
        <DocumentSentModal
          {...{
            success: documentSentSuccess,
            setShowDocumentSentModal,
            message: documentSentFailMessage,
          }}
        />
      )}
    </div>
  );
};
