import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ROUTES_ICSIGN } from "routes.js";
import { getDocuments } from "../store/documents/documents.thunks";
import { setUser } from "../store/account/account.reducer";
import { BiometricSignaturePage } from "./components/pages/biometricSignaturePage/BiometricSignaturePage";
import { CardCreatorPage } from "./components/pages/cardCreatorPage/CardCreatorPage";
import { DocumentPreviewPage } from "./components/pages/DocumentPreviewPage";
import { GroupPreviewPage } from "./components/pages/GroupPreviewPage";
import { HomePage } from "./components/pages/homePage/HomePage";
import { SignatureFragmentPage } from "./components/pages/signatureFragmentPage/SignatureFragmentPage";
import { UserIdentityPage } from "./components/pages/userIdentityPage/UserIdentityPage";
import { UserVerificationPage } from "./components/pages/userVerificationPage/UserVerificationPage";
import { UiTopBar } from "./components/common/top-bar/TopBar";
import { authService } from "./services/authService";
import LoadingContent from "./components/common/LoadingModal/LoadingContent";

export const App = () => {
  // const [user, setUser] = useState<any>({});
  // const user = {};
  const [authSuccess, setauthSuccess] = useState(false);
  const dispatch = useDispatch();

  const runAuthService = async () => {
    await authService.initialize();
    const user = await authService.getUser();

    if (!user) return;

    if (user) {
      sessionStorage.setItem("access_token", user.access_token);
      dispatch(getDocuments(user.profile.customerId));
      dispatch(setUser(user.profile));

      setauthSuccess(true);
      return true;
    }
  };

  useEffect(() => {
    runAuthService();
  }, []);

  const isWebsocketConnected = false;

  const onLogout = () => {
    authService.signOut();
  };
  return (
    <>
      {authSuccess ? (
        <>
          <UiTopBar
            showStatus={false}
            user={"user"}
            isWebsocketConnected={isWebsocketConnected}
            onLogout={onLogout}
          />
          <Switch>
            <Route>
              {/* {location.pathname === ROUTES.mobileTest ? null : <TopBar />} */}
              {/* <div className="px-4 sm:px-6 lg:px-8"> */}

              <Route exact path={ROUTES_ICSIGN.home} component={HomePage} />
              <Route
                path={ROUTES_ICSIGN.cardSignatureExample}
                component={CardCreatorPage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.signatureFragment}
                component={SignatureFragmentPage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.verifySignature}
                component={UserVerificationPage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.userIdentify}
                component={UserIdentityPage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.biometricSignature}
                component={BiometricSignaturePage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.documentSign}
                component={DocumentPreviewPage}
              />
              <Route
                exact
                path={ROUTES_ICSIGN.group}
                component={GroupPreviewPage}
              />
              {/* <Route exact path={ROUTES_ICSIGN.mobileTest} component={MobileDeviceTest} /> */}
            </Route>
          </Switch>
        </>
      ) : (
        <LoadingContent />
      )}
    </>
  );
};

export default App;
