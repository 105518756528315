import React from "react";

const LoadingContent = () => {
  return (
    <div className="loading-modal-content">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingContent;
