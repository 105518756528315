import { IButton } from "app/components/common/Button.jsx";
import { Footer } from "app/components/common/Footer/Footer.jsx";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { biometricIdentification } from "../../../../store/documents/documents.thunks";
import { documentsService } from "../../../services/documentsService";
import { SigningAnimation } from "app/components/common/SigningAnimation/SigningAnimation";
import { PdfViewer } from "../../common/PdfViewer";
import { UserIdentityResult } from "./UserIdentityResult";
import CheckboxAgreement from "app/components/common/CheckboxAgreement/CheckboxAgreement";
import useAuth from "app/components/common/useAuth";

export const UserIdentityPage = () => {
  useAuth();
  const [template, setTemplate] = useState("");
  const [data, setData] = useState();
  const [signaturesFields, setSignaturesFields] = useState();
  const [playAnimation, setPlayAnimation] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [identificationResult, setIdentificationResult] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [pdf, setPdfActions] = useState(null);
  const [canvasClean, setCanvasClean] = useState(true);

  useEffect(() => {
    documentsService.getIdentificationBase64Document().then((res) => {
      setTemplate(res.signaturesGathererBase64);
      const signatures = [];
      signatures.push({
        bottom: res.signature.bottom + res.signature.height,
        left: res.signature.left,
        width: res.signature.width,
        height: res.signature.height,
      });
      setSignaturesFields(signatures);
    });
  }, []);

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const onHandelVerificationUser = async () => {
    setPlayAnimation(true);
    const biometricData = data.getBiometricData();

    const signaturesData = biometricData.regions.map((e, index) => {
      return {
        encryptedSignatureB64: JSON.stringify({
          signatureGuid: generateRandomString(10),
          strokes: e.strokes,
          strokesDpi: 36,
          acquireTime: e.strokes[e.strokes.length - 1].utcTimeOfGathering,
        }),
        // passwordB64: e.biometric.contentBase64,
        // certificateThumbrintB64: '',
      };
    });
    const parsedSignatures = data.encryptBiometricData(
      JSON.parse(signaturesData[0].encryptedSignatureB64),
      data.publicKey
    );

    const parsedData = {
      passwordB64: parsedSignatures.key,
      certificateThumbrintB64: data.publicKey,
      encryptedSignatureB64: parsedSignatures.content,
      iv: parsedSignatures.iv,
    };

    // const parsedData = {
    //   passwordB64: biometricData.regions[0].biometric.keyBase64,
    //   certificateThumbrintB64: data.publicKey,
    //   encryptedSignatureB64: biometricData.regions[0].biometric.contentBase64,
    //   iv: biometricData.regions[0].biometric.iv,
    // };

    const identification = await dispatch(biometricIdentification(parsedData));
    if (identification) {
      setIdentificationResult(identification);
      setShowResultModal(true);
    }
  };
  return (
    <div className="content-block">
      {signaturesFields && (
        <PdfViewer
          base64Document={`data:application/pdf;base64,${template}`}
          setActions={setPdfActions}
          pdf={pdf}
          setData={setData}
          signatureFields={signaturesFields}
          onChange={(isCanvasClean) => setCanvasClean(isCanvasClean)}
        />
      )}
      <CheckboxAgreement
        variable={isCheckboxChecked}
        setVariable={setIsCheckboxChecked}
      />
      <Footer>
        <IButton
          text="Powrót do menu"
          color="WHITE"
          onClick={() => history.push("/")}
        />
        <IButton
          text="Wyczyść"
          color="WHITE"
          onClick={() => data?.clearCanvas()}
        />
        <IButton
          text="Identyfikuj"
          color="WHITE"
          onClick={() => onHandelVerificationUser()}
          disabled={!isCheckboxChecked || canvasClean}
        />
      </Footer>
      {playAnimation && (
        <SigningAnimation
          playAnimation={playAnimation}
          setPlayAnimation={setPlayAnimation}
        />
      )}
      {!playAnimation && showResultModal && (
        <UserIdentityResult
          data={identificationResult.payload}
          setShowResultModal={setShowResultModal}
        />
      )}
    </div>
  );
};
