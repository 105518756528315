import React from 'react';
import './Card.scss';

export const Card = ({ logo, text, disabled }) => {
  return (
    <div
      className="card"
      style={{
        pointerEvents: disabled ? 'none' : '',
        border: disabled ? '1px solid gray' : '',
      }}
    >
      <div className="card-image">
        {' '}
        <img src={logo} alt={logo} />{' '}
      </div>
      <div className="card-text">
        <span style={{ color: disabled ? 'gray' : '' }}>{text}</span>
      </div>
    </div>
  );
};
