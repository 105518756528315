// export const oidcSettings = {
//   authority: 'https://ucmmit.switzerlandnorth.cloudapp.azure.com/Authorization/',
//   client_id: 'icstylus_client',
//   redirect_uri: "http://localhost:3003",
//   response_type: 'code',
//   response_mode: 'query',
//   scope: 'openid profile integration_api communication_hub files_api',
//   post_logout_redirect_uri: "http://localhost:3003",
// };

export const oidcSettings = {
  authority: 'https://ip3-test.serwer.icpen.pl/auth',
  client_id: 'icstylus_client',
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: 'code',
  response_mode: 'query',
  scope: 'openid profile icsign_mobileclient_bff',
  post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
};
