import React from 'react';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import './userIdentifyPage.scss';

export const UserIdentityResult = ({ data, setShowResultModal }) => {
  return (
    <div className="identityResult">
      <div className="identityResult__content">
        {data.identificationSuccessfull && (
          <div>
            {console.log('true')}
            <TiTick
              style={{
                color: '#02a10c',
                width: '200px',
                height: '200px',
                margin: '0 auto',
              }}
            />
            <p style={{ color: '#02a10c' }}>
              Udało się zidentyfikować użytkwonika{' '}
              {data.identifiedUser.firstName} {data.identifiedUser.lastName}
            </p>
          </div>
        )}
        {!data.identificationSuccessfull && (
          <div>
            {console.log('false')}
            <ImCross
              style={{
                color: '#eb1b00',
                width: '100px',
                height: '100px',
                margin: '0 auto',
              }}
            />
            <p style={{ color: '#eb1b00' }}>
              Nie udało się zidentyfikować użytkownika
            </p>
          </div>
        )}
        <button onClick={() => setShowResultModal(false)}>Zakończ</button>
      </div>
    </div>
  );
};
