import { UserManager } from "oidc-client";

class AuthService {
  constructor(config) {
    this.userManager = new UserManager(config);
  }

  async initialize(signInCallback) {
    const signIn2 = () => this.signIn();

    try {
      await this.userManager.signinRedirectCallback();
      if (signInCallback) signInCallback();
    } catch {
      signIn2();
    }
  }

  signIn() {
    this.userManager.signinRedirect();
  }

  async signOut() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("userDataLoaded");
    await this.userManager.signoutRedirect();
  }

  async getUser() {
    return await this.userManager.getUser();
  }

  async getAccessToken() {
    const user = await this.userManager.getUser();
    return user && user.access_token;
  }

  async refreshToken() {
    const data = await this.userManager.signinSilent();
    sessionStorage.setItem("access_token", data.access_token);
    return data.access_token;
  }

  async redirect() {
    await this.signOut();
    await this.userManager.signinRedirect();
  }
}

export { AuthService };
