// import { joiResolver } from '@hookform/resolvers';
import { createAsyncThunk } from '@reduxjs/toolkit';
// import AuthService from 'app/services/authService';

// const testAsyncFunc = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({ access_token: 'asdasdas' });
//     }, 2000);
//   });
// };

export const getUser = createAsyncThunk('[ACCOUNT] GET_USER', async (authService) => {
  // const authService = new AuthService();
  const user = await authService.getUser(); //await AuthService().getUser();

  return user.access_token;
});
