import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Card } from "app/components/common/Card/Card";
import { DocumentCard } from "app/components/common/DocumentCard/DocumentCard";
import biometric from "assets/svg/biometric.svg";
import docImg from "assets/svg/document.svg";
import docImg2 from "assets/svg/docSign2.svg";
import documentsImg from "assets/svg/documents.svg";

import sponsor from "assets/svg/sponsor.png";
import verificationImg from "assets/svg/verification.svg";
import incognitoImg from "assets/svg/incognito.svg";
import signPenImg from "assets/svg/signPen.svg";
import { documentsState } from "../../../../store/documents";
import { getDocumentById } from "../../../../store/documents/documents.thunks";
import {
  selectingGroup,
  addIdsForSigning,
} from "../../../../store/documents/documents.reducer";

import "./homePage.scss";
import useAuth from "app/components/common/useAuth";

export const HomePage = () => {
  useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const ReduxStoreData = useSelector(documentsState);

  const onHandelGetDocument = async (id) => {
    dispatch(getDocumentById(id));
    history.push(`/document/${id}`);
  };

  const onHandelSelectedGroup = async (selectedGroup, orderedDocumentIds) => {
    console.log("ewew: ", selectedGroup, orderedDocumentIds);
    await dispatch(selectingGroup(selectedGroup));
    await dispatch(addIdsForSigning(orderedDocumentIds));
    history.push(`group/document/${orderedDocumentIds[0].documentId}`);
  };

  return (
    <>
      <div className="content-wrapper">
        <div>
          <div className="documents-block-wrapper">
            <div className="documents-title-block">
              <h2>Dostępne moduły aplikacji</h2>
            </div>
            <div className="cards-block">
              <Link
                to={`/card/${
                  ReduxStoreData.documents.length
                    ? ReduxStoreData.documents.elements[0].addedBy
                    : "MOCKid"
                }`}
              >
                {" "}
                <Card
                  logo={docImg2}
                  text={"Utwórz lub aktualizuj kartę wzorów podpisów"}
                />
              </Link>
              <Link
                to={`/signature`}
                style={{
                  pointerEvents: ReduxStoreData.signaturesChartExists
                    ? ""
                    : "none",
                }}
              >
                {" "}
                <Card
                  logo={signPenImg}
                  text={"Podejrzyj fragmenty wzoru podpisu"}
                  disabled={!ReduxStoreData.signaturesChartExists}
                />
              </Link>
              <Link
                to={`/verify`}
                style={{
                  pointerEvents: ReduxStoreData.signaturesChartExists
                    ? ""
                    : "none",
                }}
              >
                {" "}
                <Card
                  logo={verificationImg}
                  text={"Weryfikuj tożsamosć na podstawie podpisu"}
                  disabled={!ReduxStoreData.signaturesChartExists}
                />
              </Link>
              <Link to={`/identify`}>
                {" "}
                <Card logo={incognitoImg} text={"Identyfikuj użytkownika"} />
              </Link>
              <Link to={`/biometric`}>
                {" "}
                <Card
                  logo={biometric}
                  text={"Podpisz podpisem biometrycznym"}
                />
              </Link>
            </div>
          </div>
          <div className="documents-block-wrapper"></div>

          <div className="documents-title-block">
            <h2>Moje dokumenty do podpisania</h2>
          </div>
          <div className="cards-block">
            {ReduxStoreData.documents.documentGroupsForSigning
              ? ReduxStoreData.documents.documentGroupsForSigning.map(
                  (group) => {
                    return (
                      <div
                        onClick={() =>
                          onHandelSelectedGroup(group, group.orderedDocumentIds)
                        }
                        key={group.utcTimeOfOrder}
                      >
                        <DocumentCard logo={documentsImg} data={group} />
                      </div>
                    );
                  }
                )
              : null}
            {ReduxStoreData.documents.documentsForSigning
              ? ReduxStoreData.documents.documentsForSigning.map((doc) => {
                  return (
                    <div
                      onClick={() => onHandelGetDocument(doc.documentId)}
                      key={doc.documentId}
                    >
                      <DocumentCard logo={docImg} data={doc} />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="footer">
        <img src={"/banner.png"} alt={sponsor} />
      </div>
    </>
  );
};
