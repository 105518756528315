import { createSlice } from "@reduxjs/toolkit";
import { documentsAdapter } from "./documents.adapter";
import {
  getDocuments,
  getDocumentById,
  getPartOfSignature,
  createNewDocumentWithSignatures,
  signDocumentWithBiometricData,
  biometricIdentification,
  biometricVerification,
  checkIfSignaturesChartExists,
  // signGroupOfDocuments,
  // signSingleDocument,
} from "./documents.thunks";

export const documentsSlice = createSlice({
  name: "[DOCUMENTS]",
  initialState: documentsAdapter.getInitialState({
    documents: [],
    selectedDocument: {},
    selectedGroup: [],
    documentForBiometricSign: "",
    signaturesOnDocument: [],
    partOfUserSignature: "",
    createdLastDocument: [],
    signedDocWithBiometric: "",
    biometricVerification: [],
    biometricIdentification: [],
    isGroupSignedCorrect: "",
    isSignedDocumentCorrect: "",
    groupDocsIdsForSigning: [],
    signaturesChartExists: false,
    signaturesChartLoading: true,
  }),
  reducers: {
    ADD_DOCUMENT_FOR_UPLOAD: (state, { payload }) => {
      state.documentForBiometricSign = payload;
    },
    REMOVE_DOCUMENT_FOR_UPLOAD: (state) => {
      state.documentForBiometricSign = "";
    },
    UPDATE_SIGNATURE_TO_DOC: (state, { payload }) => {
      state.signaturesOnDocument = [...payload];
    },
    REMOVE_SIGNATURE_FROM_DOC: (state) => {
      state.signaturesOnDocument = [];
    },
    SELECTING_GROUP: (state, { payload }) => {
      state.selectedGroup = payload;
    },
    ADD_IDS_FOR_SIGNING: (state, { payload }) => {
      state.groupDocsIdsForSigning = payload;
    },
    REMOVE_ID_FOR_SIGNING: (state, { payload }) => {
      const id = state.groupDocsIdsForSigning.filter((x) => x !== payload);
      state.groupDocsIdsForSigning = id;
    },
    REMOVE_SELECTED_DOCUMENT: (state) => {
      state.selectedDocument = {};
    },
  },
  extraReducers: {
    [getDocuments.fulfilled]: (state, { payload }) => {
      state.documents = payload;
    },
    [getDocumentById.fulfilled]: (state, { payload }) => {
      state.selectedDocument = payload;
    },
    [getPartOfSignature.fulfilled]: (state, { payload }) => {
      state.partOfUserSignature = payload;
    },
    [createNewDocumentWithSignatures.fulfilled]: (state, { payload }) => {
      state.createdLastDocument = payload;
    },
    [signDocumentWithBiometricData.fulfilled]: (state, { payload }) => {
      state.signedDocWithBiometric = payload;
    },
    [biometricVerification.fulfilled]: (state, { payload }) => {
      state.biometricVerification = payload;
    },

    [biometricIdentification.fulfilled]: (state, { payload }) => {
      state.biometricIdentification = payload;
    },

    [checkIfSignaturesChartExists.fulfilled]: (state, { payload }) => {
      state.signaturesChartExists = payload;
      state.signaturesChartLoading = false;
    },
    // [signGroupOfDocuments.fulfilled]: (state, { payload }) => {
    //   state.isGroupSignedCorrect = payload;
    // },
    // [signSingleDocument.fulfilled]: (state, { payload }) => {
    //   state.isSignedDocumentCorrect = payload;
    // },
  },
});

export const {
  ADD_DOCUMENT_FOR_UPLOAD: addScanDocumentForUpload,
  REMOVE_DOCUMENT_FOR_UPLOAD: removeDocumentForUpload,
  UPDATE_SIGNATURE_TO_DOC: updateSignatureToDocument,
  REMOVE_SIGNATURE_FROM_DOC: removeSignatureFromDoc,
  SELECTING_GROUP: selectingGroup,
  ADD_IDS_FOR_SIGNING: addIdsForSigning,
  REMOVE_ID_FOR_SIGNING: removeIdForSigning,
  REMOVE_SELECTED_DOCUMENT: removeSelectedDocument,
} = documentsSlice.actions;

export default documentsSlice.reducer;
